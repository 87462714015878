.app {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background: lightpink;
}

.callMyLawyer {
  line-height: 0.2;
  text-align: center;
  color: lightgreen;
  text-shadow: 2px 2px 5px darkgreen;
  user-select: none;

  h1 {
    font-size: 10vmax;
  }

  h3 {
    font-size: 4.2vmax;
  }
}


a {
  color: inherit;
  text-decoration: none;
  cursor: url(https://res.cloudinary.com/garrettbear/image/upload/v1558385173/cursor-1.png), auto;

  &:active {
    cursor: url(https://res.cloudinary.com/garrettbear/image/upload/v1558385622/cursor-2.png), auto;
  }
}
